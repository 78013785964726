import React from 'react';
import { Image, Transition} from "semantic-ui-react";


export default function RandomTransitionImage({image}) {

    let delayInMilliseconds = Math.floor(Math.random() * 3) * 1000; //1 second

    let visible = false;
    setTimeout(() => {
        console.log('random: ', delayInMilliseconds);
        visible = true;
    }, delayInMilliseconds);

    console.log('visible: ', visible);

    return (
        <Transition visible={true} animation='fade up' duration={500}>
            <Image  fluid src={image} />
        </Transition>
    );
}