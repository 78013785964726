import {Button, Card, Grid, Header, Icon, Image, Modal, Reveal, Segment} from "semantic-ui-react";
import Pulse from "react-reveal/Pulse";
import {Parallax} from "react-scroll-parallax/cjs";
import {Roll} from "react-reveal";
import ConnectedRocket from "../../containers/ConnectedRocket";
import React from "react";
import Timeline from "../Timeline";
import PicsMasonry from "../PicsMasonry";

const rocketStyle = {
    width: '20vmax',
    zIndex: 2,
}

const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

const AboutMe = (isMobile) => (
    <Segment style={{
        padding: '2em 0em', backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        willChange: 'transform',
        width: '100%',
        height: '100%',
        transform: 'translate3d(0px, 0px, 0px)',
        backgroundImage: 'url("svg/stars.jpg")'
    }} vertical inverted>

        <div style={{}}>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Pulse>
                            <Header as='h3' inverted style={{
                                transform: 'matrix(.53 0 0 1 2.09 89.45)',
                                fontSize: 48,
                                fontFamily: 'AmericanTypewriter,American Typewriter'
                            }}>
                                I'm a...
                            </Header>
                        </Pulse>

                        <Parallax
                            y={[-10, 10]} tagOuter='squares'
                            style={{
                                position: 'relative',
                                zIndex: 2
                            }}
                        >
                            <p style={{fontSize: '1.33em'}}>
                                Software Development Manager with 17+ years of experience architecting and
                                implementing robust, flexible and performant applications that
                                scale on demand to meet customers’ needs. I am a full-stack engineer by
                                training and a manager with special focus in development life-cycle best
                                practices, taking Agile projects and building continuous integration and
                                delivery pipelines driven by automation. I am passionate about user
                                experience, always starting from our customer and working backwards to
                                provide quality software applications.
                            </p>
                        </Parallax>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8} verticalAlign={'middle'}>

                        <Parallax>
                            <PicsMasonry isMobile/>
                        </Parallax>

                    </Grid.Column>
                    <Parallax>
                        <Timeline/>
                    </Parallax>
                    <div style={{
                        position: 'absolute',
                        left: '10px'
                    }}>
                        <Parallax
                            y={[100, -300]} tagOuter='figure'
                            style={{
                                position: 'relative',
                                zIndex: 2
                            }}
                        >
                            <ConnectedRocket style={rocketStyle}/>
                        </Parallax>
                    </div>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <Button color='linkedin' onClick={() => openInNewTab("https://www.linkedin.com/in/heacosta/")}>
                            <Icon name='linkedin'/> Check My Profile
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </Segment>
)

export default AboutMe