/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, {Component} from 'react'

import {Parallax, ParallaxProvider} from "react-scroll-parallax/cjs";
import ResponsiveContainer from "./pagecontainer/ResponsiveContainer";
import AboutMe from "./pagecontainer/AboutMe";
import Awards from "./pagecontainer/Awards";
import Posts from "./pagecontainer/Posts";
import Footer from "./pagecontainer/Footer";
import ConnectedAboutMe from "../containers/ConnectedAboutMe";
import {Segment} from "semantic-ui-react";
import VideoMasonry from "./VideoMasonry";


const HomepageLayout = () => (
    <ParallaxProvider>
        <ResponsiveContainer>
            <ConnectedAboutMe />
            <Awards />
            <Posts />
            <VideoMasonry />
            <Footer />
        </ResponsiveContainer>
    </ParallaxProvider>

)

export default HomepageLayout