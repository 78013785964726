import {Grid, Header, Segment} from "semantic-ui-react";
import React from "react";

const Awards = () => (
    <Segment style={{padding: '0em'}} vertical>
        <Grid celled='internally' columns='equal' stackable>
            <Grid.Row textAlign='center'>
                <Grid.Column style={{paddingBottom: '5em', paddingTop: '5em'}}>
                    <Header as='h3' style={{fontSize: '2em'}}>
                        "Discovery Award"
                    </Header>
                    <p style={{fontSize: '1.33em'}}>Interplanetary Network Directorate <br/>Multimission Ground
                        Systems and Services Program Office</p>
                </Grid.Column>
                <Grid.Column style={{paddingBottom: '5em', paddingTop: '5em'}}>
                    <Header as='h3' style={{fontSize: '2em'}}>
                        "Voyager Award"
                    </Header>
                    <p style={{fontSize: '1.33em'}}>For design adoption of web components, standardizing the
                        look and feel among MGSS and project Web Applications</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
)

export default Awards