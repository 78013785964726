import React from 'react';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Icon, Image} from "semantic-ui-react";

export default function Timeline() {
    return (
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                date="2019 - present"
                iconStyle={{background: 'rgb(256, 256, 256)', color: '#fff'}}
                icon={<Image src={'./assets/amazon/amazon_logo.png'} size={'huge'}/>}
            >
                <h3 className="vertical-timeline-element-title">Software Development Manager - Amazon</h3>
                <h4 className="vertical-timeline-element-subtitle">Seattle, WA</h4>
                <p>
                    I lead a team of engineers that provide scalable, reliable and interoperable services that enable
                    Big Data Customers' end-to-end job run lifecycle. We provide services that orchestrate workflows
                    from job run creation, through dependency resolution, resource allocation and execution.
                    Our team supports workflows from Amazon.com, Alexa, Connected Devices, Music, Security, among others.
                    We run over 25 million daily job runs, efficiently operating at Amazon's scale.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                date="2016 - 2019"
                iconStyle={{background: 'rgb(22, 63, 140)', color: '#fff'}}
                icon={<Image size={'huge'} src={'./assets/nasa/nasa_logo.png'}/>}
            >
                <h3 className="vertical-timeline-element-title">Software Development Manager - JPL/NASA</h3>
                <h4 className="vertical-timeline-element-subtitle">Pasadena, CA</h4>
                <p>
                    <ul>
                        <li>Designed and implemented a service-oriented architecture with capabilities
                    for mission activity planning, scheduling and simulation of different kinds of
                            spacecrafts, from rovers to landers, and CubeSats. </li>
                            <li>I implemented micro-services using Java and Spring Boot for the back-end and
                                a component-based single-page web application with AngularJS. </li>
                    <li>Architected solutions delivery, mapping capabilities to components to provide
                        a system tailored to the user needs. </li>
                        <li>Deputy Manager/Engineering Applications Subsystems (EAS) for Mars 2020. </li>
                    <li>Revamped and grew team from 4 to 23 engineers, by earning customers’ trust
                    and delivering on our commitment to reliable and scalable applications to aid on
                        space exploration. </li>
                    </ul>
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                date="2013 - 2016"
                iconStyle={{background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<Image size={'huge'} src={'./assets/fox/fox_logo.png'}/>}
            >
                <h3 className="vertical-timeline-element-title">Software Architect - Fox Filmed Entertainment</h3>
                <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
                <p>
                    <ul>
                        <li>Lead Architect for Enterprise Media Framework (EMF). Designing, implement-
                    ing and managing development of scalable, quality based media management
                            solutions that integrate into Fox’s media supply chain to drive business value.</li>
                    <li>Used Google's Polymer to deliver HTML Custom Elements for full functionality of
                    Uploads and Downloads using Aspera Web Client and Open Text Media Management Platform. </li>
                    </ul>
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                date="2007 - 2013"
                iconStyle={{background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<Image src={'./assets/disney/disney_logo.png'}/>}
            >
                <h3 className="vertical-timeline-element-title">Software Development Engineer - Disney</h3>
                <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
                <p>
                    <ul>
                        <li>Designed and implemented Media Monorail REST API for distribution and
                    delivery of assets around the world in 200+ destinations and using Digital Asset
                            Management system as one of the sources. </li>
                    <li>Designed and Managed the implementation for the Enterprise Media Management
                        Services ingest tool as well as architected the services and orchestrated
                    processes for the intake and distribution of the company's digital assets, moving
                        an average of 15 TB per day. </li>
                    </ul>
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                date="2002 - 2006"
                iconStyle={{background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<Image src={'./assets/udlap/udlap_logo.png'}/>}
            >
                <h3 className="vertical-timeline-element-title">B.S. Computer Systems Engineering - UDLA-P</h3>
                <h4 className="vertical-timeline-element-subtitle">Puebla, Mexico</h4>
                <p>
                    I did my major in Computer Systems Engineering, graduating with a thesis in Robotics.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                iconStyle={{background: 'rgb(16, 204, 82)', color: '#fff'}}
            />
        </VerticalTimeline>
    );
}