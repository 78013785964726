import {Container, Grid, Header, List, Segment} from "semantic-ui-react";
import React from "react";

const Footer = () => (
    <Segment inverted vertical style={{padding: '5em 0em'}}>
        <Container>
            <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Header inverted as='h4' content='Contact Me'/>
                        <List link inverted>
                            <List.Item>
                                <List.Icon name='mail' />
                                <List.Content>
                                    <a href='mailto:hectoracosta@me.com'>hectoracosta@me.com</a>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='marker' />
                                <List.Content>Mercer Island, WA</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='linkedin' />
                                <List.Content>
                                    <a href='https://www.linkedin.com/in/heacosta/'>heacosta</a>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column verticalAlign={'bottom'} textAlign={'right'} width={8}>
                        <p>
                            All rights reserved. Hector R. Acosta © 2023
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>
)

export default Footer