import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {Card, Image, Transition} from "semantic-ui-react";
import RandomTransitionImage from "./RandomTransitionImage";

const myArray = [
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/1.jpg',
        },
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/6.png',
        },
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/8.png',
        },
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/9.png',
        },
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/ingenieria.jpg',
        },
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/roboticsTeam.png',
        },
        {
                number: 1,
                title: 'Users',
                image: './assets/gallery/udlap.jpg',
        },{
                number: 1,
                title: 'Users',
                image: './assets/gallery/IMG_0815.png',
        },{
                number: 1,
                title: 'Users',
                image: './assets/gallery/IMG_1950.png',
        },{
                number: 1,
                title: 'Users',
                image: './assets/gallery/IMG_2588.png',
        },{
                number: 1,
                title: 'Users',
                image: './assets/gallery/IMG_2596.png',
        },{
                number: 1,
                title: 'Users',
                image: './assets/gallery/IMG_3801.png',
        },

]

const cards = () => {
        myArray.map((item, index) => (
            <Card key={index} style={{margin: '0.5em'}}>
                    {console.log('item: ', item)}
                    <RandomTransitionImage image={item.image} />
            </Card>
        ))
}

export default function PicsMasonry({isMobile}) {
        return (
            <Card.Group  fluidstackable stackable itemsPerRow={!isMobile ? 2 : 3}>
                    {myArray.map((item, index) => (
                        <Card key={index} style={{margin: '0.5em'}}>
                                {console.log('item: ', item)}
                                <RandomTransitionImage image={item.image} />
                        </Card>
                    ))}
            </Card.Group>
        );
}