import {ADD_POST, GET_POSTS} from '../actions/actionTypes';


export default function postsReducer(state = [], action) {

    switch (action.type) {

        case ADD_POST: {
            const { ...post} = action;
            return [...state, post.payload]
        }

        case GET_POSTS: {

            const {posts} = action;
            return posts
        }

        default:
            return state;
    }
}

