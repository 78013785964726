
import {connect} from 'react-redux';
import { setIsMobile } from '../actions'
import HomepageHeading from "../components/pagecontainer/HomepageHeading";

const mapStateToProps = (state, props) => {
    return {
        isMobile: state.navigation.isMobile
    };
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        setMobile: (isMobile) => {
            console.log('here! ')
            dispatch(setIsMobile(isMobile))
        }
    }
}

const ConnectedHomepageHeading = connect(mapStateToProps, mapDispatchToProps)(HomepageHeading)

export default ConnectedHomepageHeading;