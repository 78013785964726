import React from "react";
import {createMedia} from "@artsy/fresnel";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";
import {Sidebar} from "semantic-ui-react";
import ConnectedDesktopContainer from "../../containers/ConnectedDesktopContainer";
import ConnectedMobileContainer from "../../containers/ConnectedMobileContainer";

const {MediaContextProvider, Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

const ResponsiveContainer = ({children}) => (
    /* Heads up!
     * For large applications it may not be best option to put all page into these containers at
     * they will be rendered twice for SSR.
     */
    <MediaContextProvider>
        <Media greaterThan='mobile'>
            <ConnectedDesktopContainer>{children}</ConnectedDesktopContainer>
        </Media>
        <Media as={Sidebar.Pushable} at='mobile'>
            <ConnectedMobileContainer>{children}</ConnectedMobileContainer>
        </Media>
    </MediaContextProvider>
)

export default ResponsiveContainer