import React from 'react'

import SvgHello from '../images/svg/SvgHello'
import SvgHelloHorizontal from "../images/svg/SvgHelloHorizontal";
import {Image} from "semantic-ui-react";

const mystyle = {
    height: '5vh',
}
const HelloHorizontal = () => {
    return <Image src={'./svg/helloHorizontal.svg'} size={'small'}></Image>
}


export default HelloHorizontal