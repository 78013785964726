import { combineReducers } from 'redux';
import animationReducer from './animationReducer';
import drawerReducer from './drawerReducer';
import postsReducer from './postsReducer';
import navigationReducer from './navigationReducer';

const hectorApp = combineReducers({
    navigation: navigationReducer,
    animation: animationReducer,
    drawer: drawerReducer,
    posts: postsReducer
})

export default hectorApp;