import React, {useState, useEffect} from "react";

import posed from 'react-pose';

const HelloContainer = posed.g({
    open: {
        x: '0%',
        delayChildren: 400,
        staggerChildren: 200
    },
    closed: { x: '200%', delay: 500 },
    initialPose: 'closed'
});

const HelloElement = posed.text({
    open: { y: 46, opacity: 1 },
    closed: { y: -250, opacity: 0 }
});


function SvgHello(props) {

    const [open, setOpen] = useState(false);


    useEffect(() => {
        setTimeout(setOpen(true), 1000);
    });


    return (
        <svg viewBox="0 0 132 106.97" {...props}>
            <g data-name="Layer 2">
                <HelloContainer data-name="Layer 1" fill="#fff" letterSpacing="-.1em" pose={open ? 'open' : 'closed'}>
                    <HelloElement
                        transform="translate(0 46.85)"
                        fontSize={56.21}
                        fontFamily="BritannicBold,Britannic Bold"
                    >
                        {"hello, "}
                    </HelloElement>
                    <text
                        transform="matrix(.53 0 0 1 2.09 89.45)"
                        fontSize={48}
                        fontFamily="AmericanTypewriter,American Typewriter"
                    >
                        {"I\u2019m Hector"}
                    </text>
                </HelloContainer>
            </g>
        </svg>
    );
}

export default SvgHello;
