import React, {Component} from "react";
import {Container, Icon, Menu, Segment, Sidebar} from "semantic-ui-react";
import Hello from "../Hello";
import HomepageHeading from "./HomepageHeading";
import {createMedia} from '@artsy/fresnel'
import {setIsMobile, toggleSidebar} from "../../actions";
import ConnectedHomepageHeading from "../../containers/ConnectedHomepageHeading";


const MobileContainer = ({children, sidebarOpened, isMobile, handleSidebarHide, setIsMobile}) => {

    return (
        <>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={() => handleSidebarHide(false)}
                    vertical
                    visible={sidebarOpened}
                >
                    <Menu.Item as='a' active>
                        Home
                    </Menu.Item>
                    <Menu.Item as='a'>Resume</Menu.Item>
                    <Menu.Item as='a'>Profile</Menu.Item>
                    <Menu.Item as='a'>e-Mail Me</Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{minHeight: 350, padding: '1em 0em', background: 'linear-gradient(#000, #07203e)'}}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item
                                    onClick={() => handleSidebarHide(true)}
                                >
                                    <Icon name='sidebar'/>
                                </Menu.Item>
                                <Menu.Item position='right'>
                                    <Hello/>
                                </Menu.Item>
                            </Menu>
                        </Container>
                        <ConnectedHomepageHeading mobile={true}/>
                    </Segment>

                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    )
}

export default MobileContainer