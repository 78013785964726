
import { connect } from 'react-redux';
import {toggleSidebar, setIsMobile} from '../actions';
import MobileContainer from "../components/pagecontainer/MobileContainer";


const mapStateToProps = (state, props) => {
    return {
        sidebarOpened: state.navigation.sidebarOpened,
        isMobile: state.navigation.isMobile,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleSidebarHide: (isOpen) => dispatch(toggleSidebar(isOpen)),
        setIsMobile: (isMobile) => dispatch(setIsMobile(isMobile))
    }
}

const ConnectedMobileContainer = connect(mapStateToProps, mapDispatchToProps)(MobileContainer)

export default ConnectedMobileContainer;
