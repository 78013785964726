

import {connect} from 'react-redux';
import AboutMe from "../components/pagecontainer/AboutMe";


const mapStateToProps = (state, props) => {
    return {
        isMobile: state.navigation.isMobile
    };
}

const mapDispatchToProps = (dispatch, props) => {
    return { }
}

const ConnectedAboutMe = connect(mapStateToProps, mapDispatchToProps)(AboutMe)

export default ConnectedAboutMe;