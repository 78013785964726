import React from 'react';
import {
    Button,
    Card,
    Container,
    Divider,
    Header,
    Icon,
    Image,
    Segment,
    Transition
} from "semantic-ui-react";
import EmbeddedVideo from "./EmbeddedVideo";

const myArray = [
        {
                id: 'BRWhGGpu_0Q',
                title: 'Rock Francais',
                video: 'https://youtu.be/BRWhGGpu_0Q',
            share: 'https://www.icloud.com/iclouddrive/0d2wjTY67oK2FA_ycQg0Uc4CA#RockFrancais',
        },
    {
        id: 'RwFNLreSDeI',
        title: 'Westminster 50 Aniversario',
        video: 'https://youtu.be/RwFNLreSDeI',
        share: 'https://www.icloud.com/iclouddrive/0df2ocPu2v9JUL_qiK8az1Glw#Westimnster50Aniversario',
    },
    {
        id: '_j_btJ7AGWg',
        title: 'Westminster Grad. 2002',
        video: 'https://youtu.be/_j_btJ7AGWg',
        share: 'https://www.icloud.com/iclouddrive/0871LTjGQbcxr6zT3do9Yc-gA#WestminsterGraduacion2002',
    },
    {id: 'BrtXy5MgmPo',
        title: 'Westminster Cena/Baile 2002',
        video: 'https://youtu.be/BrtXy5MgmPo',
        share: 'https://www.icloud.com/iclouddrive/0a7I6u8uDYFm2SiXHA5Tcodzg#WestminsterCenaBaile2002',
    },
    {
        id: 'SH3l1xzfGeU',
        title: 'Westminster Día De Las Madres 2002 2a. Parte',
        video: 'https://youtu.be/SH3l1xzfGeU',
        share: 'https://www.icloud.com/iclouddrive/0d6JduBKidrkBlqKnXLEl30Xw#WestminsterDiaDeLasMadres20022aParte_2',
    },
    {
        id: 'vL6H1-Y176k',
        title: 'Westminster Gen. 90-96',
        video: 'https://youtu.be/vL6H1-Y176k',
        share: 'https://www.icloud.com/iclouddrive/087N-clL75nBNsiM_kZE3pkuA#WestminstrGeneration90-96',
    },
]

const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

export default function VideoMasonry({isMobile}) {
        return (
            <Segment>
                <Container text>
                    <Divider
                        as='h4'
                        className='header'
                        horizontal
                        style={{margin: '1em 0em'}}
                    >
                        <a href='#'>Oldies but Goodies for Friends</a>
                    </Divider>
                </Container>

            <Card.Group  fluidstackable stackable itemsPerRow={isMobile ? 2 : 3}>
                    {myArray.map((item, index) => (
                        <Card key={index} style={{margin: '0.5em'}}>
                            <EmbeddedVideo id={item.id} />
                            <div align={'center'} style={{margin: '1em'}}>
                                <Button color='youtube' onClick={() => openInNewTab(item.video)}>
                                    <Icon name='youtube' /> {item.title}
                                </Button>
                                <Button color='olive' onClick={() => openInNewTab(item.share)}>
                                    <Icon name='share' /> Save a Copy
                                </Button>
                            </div>
                        </Card>
                    ))}
            </Card.Group>

            </Segment>
        );
}