import React, {useState, useEffect} from "react";

function SvgHelloHorizontal(props) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="50 50 248.7 61.97">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <text style={{fontSize: '56.21px', fontFamily: 'BritannicBold, Britannic Bold', letterSpacing: '-0.07em'}} transform="translate(0 46.85)">hello -</text>
                    <text style={{fontSize: '48px', fontFamily: 'AmericanTypewriter, American Typewriter', letterSpacing: '-0.07em'}} transform="translate(136.68 44.45) scale(0.53 1)">I’m Hector</text>
                </g>
            </g>
        </svg>
    );
}

export default SvgHelloHorizontal;
