import {
    CLOSE_DRAWER,
    OPEN_DRAWER,
    ADD_POST,
    GET_POSTS,
    PLAY_ANIMATION,
    HIDE_FIXED_MENU,
    SHOW_FIXED_MENU, IS_MOBILE
} from './actionTypes';

export const toggleAnimation = (visible) => {
    return {
        type: PLAY_ANIMATION,
        payload: {
            visible: !visible,
        }
    }
}

export const openDrawer = (content) => {
    return {
        type: OPEN_DRAWER,
        payload: {
            open: true,
            content
        }
    }
}

export const closeDrawer = (content) => {
    return {
        type: CLOSE_DRAWER,
        payload: {
            open: false,
            content: content
        }
    }
}

export const createPost = (content, category, date) => {
    return {
        type: ADD_POST,
        payload: {
            date,
            category,
            content
        }
    }
}

export const getPosts = (filter) => {
    return {
        type: GET_POSTS,
        payload: {
            filter
        }
    }
}


export const hideFixedMenu = () => {
    return {
        type: HIDE_FIXED_MENU,
        payload: {
            fixedMenu: false
        }
    }
}

export const showFixedMenu = () => {
    return {
        type: SHOW_FIXED_MENU,
        payload: {
            fixedMenu: true
        }
    }
}

export const setIsMobile = (isMobile) => {
    return {
        type: IS_MOBILE,
        payload: {
            isMobile: isMobile
        }
    }
}

export const toggleSidebar= (sidebarState) => {
    console.log('sidebar: ', sidebarState)
    return {
        type: SHOW_FIXED_MENU,
        payload: {
            sidebarOpened: sidebarState
        }
    }
}