import React from 'react'

import SvgHello from '../images/svg/SvgHello'

const mystyle = {
    width: '15vmax',
    position: 'absolute',
    // margin: 10,
    top: '2em',
    right: '2em',
    zIndex: 1,
}
const Hello = () => {
    return <SvgHello style={mystyle}></SvgHello>
}

export default Hello