import { createStore } from 'redux';
import hectorApp from '../reducers';

import DevTools from '../containers/DevTools';


const initState = {
    navigation: {
        fixedMenu: false,
        sidebarOpened: false,
        isMobile: false,
    },
    animation: {
        visible: true
    },
    drawer: {
        open: false,
        content: null
    },
    posts: [
        {
            id: 1,
            title: 'Redux + React',
            content: '',
            category: 'Tutorial',
            keywords: ['Redux', 'React', 'Tutorial', 'coding', 'web'],
            images: []
        },
        {
            id: 2,
            title: 'Streaming Wars',
            content: '',
            category: 'Opinion',
            keywords: [],
            images: []
        },
        {
            id: 3,
            title: 'Design Patterns',
            content: '',
            category: 'Opinion',
            keywords: [],
            images: []
        }
    ]
}

const store = createStore(hectorApp, initState, DevTools.instrument());

export default store;