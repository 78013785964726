import { PLAY_ANIMATION } from '../actions/actionTypes';


export default function animationReducer(state = {visible: true}, action) {

    switch (action.type) {
        case PLAY_ANIMATION: {
            const {payload = {}} = action;
            return payload;
        }

        default:
            return state;
    }

}