
import SvgRocket from '../components/SvgRocket'

import { connect } from 'react-redux';


const mapStateToProps = (state, props) => {
    return {}
}

const ConnectedRocket = connect(mapStateToProps)(SvgRocket)

export default ConnectedRocket;
