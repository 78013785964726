import { OPEN_DRAWER, CLOSE_DRAWER } from '../actions/actionTypes';


export default function drawerReducer(state = {open: false, content: null}, action) {

    switch (action.type) {
        case OPEN_DRAWER: {
            const {payload = {}} = action;
            return payload;
        }

        case CLOSE_DRAWER: {
            const {payload = {}} = action;
            return payload;
        }

        default:
            return state;
    }

}