import {Container} from "semantic-ui-react";
import ConnectedHector from "../../containers/ConnectedHector";
import React from "react";

const hectorStyle = {
    width: '40vmax',
}

const HomepageHeading = ({mobile, isMobile, setMobile}) => (
    <Container style={{padding: '50px 0'}}>
        { setMobile(mobile) }
        <ConnectedHector style={hectorStyle}/>
    </Container>
)

export default HomepageHeading