/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
import React, {Component} from "react";
import {Container, Menu, Segment, Visibility} from "semantic-ui-react";
import HelloHorizontal from "../HelloHorizontal";
import Hello from "../Hello";
import HomepageHeading from "./HomepageHeading";
import ConnectedHomepageHeading from "../../containers/ConnectedHomepageHeading";


const DesktopContainer = ({children, fixedMenu, hideFixedMenu, showFixedMenu}) => {

    console.log('0', children);
    console.log('1', fixedMenu);
    console.log('2', hideFixedMenu);
    console.log('3', showFixedMenu);

    return (
            <>
            <Visibility
                once={false}
                onBottomPassed={() => showFixedMenu()}
                onBottomPassedReverse={() => hideFixedMenu()}
            >
                <Segment
                    inverted
                    textAlign='center'
                    style={{minHeight: 700, padding: '1em 0em', background: 'linear-gradient(#000, #07203e)'}}
                    vertical
                >
                    <Menu
                        fixed={fixedMenu ? 'top' : null}
                        inverted={!fixedMenu}
                        pointing={!fixedMenu}
                        secondary={!fixedMenu}
                        size='large'
                    >
                        <Container>
                            {/*<Menu.Item as='a' active>*/}
                            {/*    Home*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item as='a'>Resume</Menu.Item>*/}
                            {/*<Menu.Item as='a'>Profile</Menu.Item>*/}
                            {/*<Menu.Item as='a'>e-Mail Me</Menu.Item>*/}
                            <Menu.Item position='right'>
                                {fixedMenu ? <HelloHorizontal style={{height: '50'}}/> : <Hello/>}
                            </Menu.Item>
                        </Container>
                    </Menu>
                    <ConnectedHomepageHeading mobile={false}/>
                </Segment>

            </Visibility>
            {children}
            </>

    )
}

export default DesktopContainer