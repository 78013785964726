import {Button, Container, Divider, Header, Icon, Segment} from "semantic-ui-react";
import React from "react";
import VideoMasonry from "../VideoMasonry";

const Posts = () => (
    <Segment style={{padding: '1em 0em'}} vertical>
        <Container text>
            <Divider
                as='h4'
                className='header'
                horizontal
                style={{margin: '3em 0em'}}
            >
                <a href='#'>Open Source</a>
            </Divider>

            <Header as='h3' style={{fontSize: '2em'}}>
                My work at NASA's Jet Propulsion Lab
            </Header>
            <Header as='h4' style={{fontSize: '.75em'}}>
                by Hector R. Acosta - OCT/08/2019
            </Header>
            <p style={{fontSize: '1em'}}>
                I led development on 3 different areas of the Ground Systems used for JPL missions. The concerns are
                split in 3 areas: Missions Planning, Sequencing & Commanding, and Telemetry. This follows the mission
                planning and execution flywheel. We provide tools for doing planning (sometimes aided by AI, using
                goal-based planning). After a plan is created, taking into consideration the activities needed and the
                resources available, we generate these sequences and commands into instructions that the spacecraft can
                understand. Because the nature of the multi-year conception for a mission, the architecture of each
                spacecraft is different. Once a command is executed, the state changes. We capture these changes and
                them send them back to ground operations using the Deep Space Network (DSN). We provide tools to visualize this telemetry
                for scientists to analyze. Based on the new state of the spacecraft, a new activity is planned, and the
                whole cycle starts all over. We strongly believe that this software can be used for other purposes. I am very proud
                of my team, and how we open-sourced these tools.
            </p>
            <br />
            <p>
                Aerie is a software framework for modeling spacecraft. Its main features include:
                <ul>
                    <li>A Java-based mission modeling library</li>
                    <li>A discrete-event simulator</li>
                    <li>An embedded TypeScript DSL for defining and executing scheduling goals</li>
                    <li>An embedded TypeScript DSL for defining and executing constraints</li>
                    <li>An embedded TypeScript DSL for defining and executing activity command expansions</li>
                    <li>An embedded TypeScript DSL for defining sequences</li>
                    <li>A GraphQL API</li>
                    <li>A web-based client application</li>
                </ul>
            </p>
                <br />
            <p>
                <Button color={'blue'} onClick={() => openInNewTab("https://github.com/NASA-AMMOS/aerie")}>
                    <Icon name='github'/> Chek out Aerie in Github!
                </Button>
            </p>
        </Container>
    </Segment>

)

const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

export default Posts