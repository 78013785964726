
import { connect } from 'react-redux';
import {hideFixedMenu, showFixedMenu} from '../actions';
import DesktopContainer from "../components/pagecontainer/DesktopContainer";


const mapStateToProps = (state, props) => {
    return {
        fixedMenu: state.navigation.fixedMenu
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        hideFixedMenu: () => dispatch(hideFixedMenu()),
        showFixedMenu: () => dispatch(showFixedMenu())
    }
}

const ConnectedDesktopContainer = connect(mapStateToProps, mapDispatchToProps)(DesktopContainer)

export default ConnectedDesktopContainer;
