export const OPEN_DRAWER= 'OPEN_DRAWER';
export const CLOSE_DRAWER= 'CLOSE_DRAWER';
export const GET_POSTS= 'GET_POSTS';
export const FILTER_POSTS = 'FILTER_POSTS';
export const ADD_POST = 'ADD_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const PLAY_ANIMATION = 'PLAY_ANIMATION';



export const HIDE_FIXED_MENU = 'HIDE_FIXED_MENU';
export const SHOW_FIXED_MENU = 'SHOW_FIXED_MENU';
export const IS_MOBILE = 'IS_MOBILE';