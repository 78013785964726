import React from 'react';
import {Embed, Image, Transition} from "semantic-ui-react";


export default function EmbeddedVideo({id}) {

    return (
        <Embed
            id={id} 
            placeholder='./assets/colorBarsVideo.png'
            source='youtube'
        />
    );
}