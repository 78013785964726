import {HIDE_FIXED_MENU, IS_MOBILE, SHOW_FIXED_MENU} from '../actions/actionTypes';



export default function navigationReducer(state = {fixedMenu: false}, action) {

    const {type, payload} = action;
    switch (type) {

        case HIDE_FIXED_MENU: {
            return {...payload};
        }
        case SHOW_FIXED_MENU: {
            return {...payload};
        }
        case SHOW_FIXED_MENU: {
            return {...payload};
        }
        case IS_MOBILE: {
            return {...payload};
        }

        default:
            return state;
    }

}