import React from 'react';
import HomepageLayout from "./components/HomepageLayout";
import DevTools from './containers/DevTools';


const App = ({store}) => {

    return (
        <div className="App">
            <HomepageLayout/>
            {/*<DevTools />*/}
        </div>
    )
}

export default App;
