
import SvgComposition from '../components/SvgComposition'

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {openDrawer, toggleAnimation} from '../actions';


const mapStateToProps = (state, props) => {
    return {
        animation: state.animation
    }
}

const mapDispatchToProps = (dispatch, props) => bindActionCreators({openDrawer, toggleAnimation}, dispatch)

const ConnectedHector = connect(mapStateToProps, mapDispatchToProps)(SvgComposition)

export default ConnectedHector;
